@import './variables';

@import './components';
@import './helpers';
@import './fonts';
@import './typography';

html {
  background: $primary-bg;
  color: $default-color;
  font-family: $main-ff;
  font-size: 16px;
  height: 100%;
}
body {
  height: 100%;
  overflow-x: hidden;
}
app {
  display: block;
  height: 100%;
}

p-dropdown.ng-invalid.ng-touched:not(form) > div {
  border: 1px solid $error-color;
}

// p-dropdown.ng-invalid.ng-touched.ui-inputwrapper-focus:not(form) > div {
//   // box-shadow: 0 0 0 0.05em #F40000;
//   border: 1px solid $error-color;
//   // border-radius: 2px;
// }

// p-dropdown.ng-invalid.ng-untouched.ui-inputwrapper-focus:not(form) > div {
//   // box-shadow: 0 0 0 0.05em #F40000;
//   border: 1px solid $error-color;
//   // border-radius: 2px;
// }

.modal-open {
  pointer-events: none;
  opacity: 0.1;
}
