@use "sass:math";

@import '../variables';

$_bg: (
  default: transparent,
  hover: transparent,
  focus: transparent,
  disabled: rgba(#ccc, 0.1),
);
$_border: (
  default: rgba(#fff, 0.5),
  hover: #fff,
  focus: accent-palette(500),
  disabled: #ccc,
);
$_color: (
  default: #fff,
  hover: #fff,
  focus: #fff,
  disabled: #ccc,
);

$_placeholder-color: #a8a8a8;
$_border-style: solid;
$_border-width: 1px;
$_fs: 1rem;
$_lh: math.div($module-rem * 2, $_fs);
$_padding-h: $module-rem;
$_padding-v: ($module-rem * 0.5);
$_shape: 3px;
$_animation: $animation;

$_border-color-panel: rgba(#000, 0.15);

html {
  .ant-picker {
    background-color: map-get($_bg, default);
    background-clip: padding-box;
    border: $_border-width $_border-style map-get($_border, default);
    border-radius: $_shape;
    cursor: pointer;
    display: flex;
    position: relative;
    line-height: $_lh;
    min-height: (4 * $module);
    transition: background-color 0.2s $_animation, border 0.2s $_animation,
      color 0.2s $_animation;
    will-change: background-color, border, color;
    font: inherit;
    font-weight: 400;
    margin: -$_border-width 0;
    outline: none;
    padding: $_padding-v ($_padding-h + 0.2rem);
    width: 100%;

    &:hover {
      background-color: map-get($_bg, hover) !important;
      border-color: map-get($_border, hover) !important;
      outline: none !important;
    }

    &:disabled,
    &.ant-picker-disabled {
      background-color: map-get($_bg, disabled) !important;
      border-color: map-get($_border, disabled) !important;
    }

    &:focus,
    &.ant-picker-focused {
      outline: none !important;
      box-shadow: none;
    }

    input {
      color: map_get($_color, default);
    }

    .ant-picker-separator {
      .anticon {
        vertical-align: top;
      }
    }
    .ant-picker-active-bar {
      background: accent-palette(500) !important;
    }

    .anticon-calendar {
      height: 20px;
      vertical-align: 0;
      margin: 0;

      > svg {
        fill: rgba(#fff, 0.5);
      }
    }

    .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
    .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
      background: main-palette(500);
      color: accent-palette(500);
    }
  }

  .ant-picker-panel,
  .ant-picker-panel .ant-picker-footer {
    border-color: $_border-color-panel;
  }

  .ant-picker-footer .ant-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid $_border-color-panel;
  }

  .ant-calendar,
  .ant-picker-panel-container {
    background: main-palette(400);
    border-color: map_get($_border, default);
    color: accent-palette(500);

    thead th {
      color: accent-palette(400) !important;
    }
  }

  .ant-picker-datetime-panel .ant-picker-time-panel,
  .ant-picker-time-panel-column:not(:first-child) {
    border-color: $_border-color-panel;
  }
  .ant-picker-header {
    color: map_get($_color, default);
    border-color: $_border-color-panel;

    > button {
      color: rgba(#ccc, 0.5);

      &:hover {
        color: #ccc;
      }
    }
  }

  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: main-palette(500);
  }

  .ant-picker-ok button:not(:disabled) {
    background: main-palette(700) !important;
    border-radius: 0 !important;
    border: none !important;
    color: #fff !important;
  }

  .ant-picker-content,
  .ant-calendar-year-panel {
    .ant-picker-cell {
      color: rgba(#ccc, 0.5);

      &:hover {
        .ant-picker-cell-inner {
          background: main-palette(500) !important;
        }
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-today {
      .ant-picker-cell-inner::before {
        border-color: accent-palette(500) !important;
      }
    }

    .ant-picker-cell-in-view {
      * {
        border-color: accent-palette(500) !important;
      }

      .ant-picker-cell-inner {
        color: #ccc;
      }

      .ant-picker-cell-in-range::before {
        background: main-palette(300);
      }

      &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start,
      &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single) {
        &:after,
        &:before {
          background: main-palette(500);
        }
      }

      &.ant-picker-cell-in-range,
      &.ant-picker-cell-range-end {
        &.ant-picker-cell-range-hover::before {
          background: main-palette(200);
        }
      }

      &.ant-picker-cell-in-range::before {
        background: main-palette(300);
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
      &.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
      &.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
      &.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
        border-color: accent-palette(500) !important;
      }

      &.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
        .ant-picker-cell-inner::after {
        background: main-palette(500);
      }
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
      background: main-palette(500);
      color: accent-palette(500);
    }
  }

  .ant-picker-week-panel {
    .ant-picker-week-panel-row-selected > td {
      background: main-palette(300);
    }

    .ant-picker-week-panel-row:hover td {
      background: main-palette(200);
    }
  }

  .ant-picker {
    width: 100%;

    > * {
      width: 100% !important;
    }

    &:not(.ant-input-disabled) .ant-input {
      border: $_border-width $_border-style map-get($_border, default);
      min-height: unset;
      width: 100%;
    }

    &:hover {
      border-color: map-get($_border, hover);
    }

    &:not(.ant-input-disabled) .ant-input {
      border: $_border-width $_border-style map-get($_border, default);
      min-height: unset;
    }
  }

  .ant-calendar-picker,
  .ant-calendar-picker-input {
    max-width: 100%;
    outline: none;

    :not(.ant-input-disabled),
    &:focus {
      border: none;
      box-shadow: none;
      outline: none !important;
    }
  }

  .anticon-close-circle {
    color: #fff;
    height: 18px;
    width: 18px;
    transition: opacity 0.3s ease-in-out;
  }

  .ant-picker-clear {
    background: map-get($_bg, default) !important;
    color: rgba(#4a505c, 0.5) !important;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .ant-picker-time-panel-column .ant-picker-time-panel-cell {
    &.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background: main-palette(500);
      }
    }

    .ant-picker-time-panel-cell-inner {
      color: #ccc;

      &:hover {
        background: main-palette(300);
      }
    }

    .ant-calendar-time-picker-select-option-selected,
    li:hover {
      background: main-palette(300);
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
    background: main-palette(500);
  }
}

.ant-picker-range-arrow {
  display: none !important;
}
