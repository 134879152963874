.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.nowrap {
  white-space: nowrap !important;
}
.chat-container {
  height: 200px !important;
  position: relative;
  overflow: hidden;

  &.container-h-100 {
    height: 100px !important;
  }
  &.container-h-50 {
    height: 50px !important;
  }
  &.container-h-300 {
    height: 300px !important;
  }
  &.negative-indents {
    margin-top: -$module-rem * 2;
    margin-bottom: -$module-rem * 2;
  }
}
.ngx-charts text {
  fill: #fff !important;
}
.ngx-charts .grid-panel {
  &.odd {
    rect {
      fill: rgba(#fff,0.05);
    }
  }
  .gauge-tick {
    path {
      stroke: rgba(#fff,0.05);;
    }
    text {
      fill: rgba(#fff,0.05);;
    }
  }
}
