.cal-month-view .cal-header {
  text-align: center;
	font-weight: bolder;
	transition: background 0.4s;
 }

.cal-month-view .cal-cell-row:hover {
	background-color: #2c435b;
	transition: background 0.4s;
}

.cal-month-view .cal-header .cal-cell {
  padding: 5px 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  display: block;
  white-space: nowrap; }

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
	background-color: #07223e;
	transition: background 0.4s; }

.cal-month-view .cal-days {
  border: 1px solid #041325;
  border-bottom: 0; }

.cal-month-view .cal-cell-top {
	min-height: 78px;
	
  -webkit-box-flex: 1;
      -ms-flex: 1;
					flex: 1;
				 }

.cal-month-view .cal-cell-row {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex; }

.cal-month-view .cal-cell {
  float: left;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.cal-month-view .cal-day-cell {
  min-height: 100px; }

.cal-month-view .cal-day-cell:not(:last-child) {
  border-right: 1px solid #041325; }

.cal-month-view .cal-days .cal-cell-row {
  border-bottom: 1px solid #041325; }

.cal-month-view .cal-day-badge {
  margin-top: 18px;
  margin-left: 10px;
  background-color: #e24d4d;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px; }

.cal-month-view .cal-day-number {
  font-size: 1.2em;
  font-weight: 400;
  margin-top: 15px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px; }

.cal-month-view .cal-events {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin: 3px;
  line-height: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.cal-month-view .cal-event {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  background-color: #1e90ff;
  border-color: #041325;
  color: #fff; }

.cal-month-view .cal-event-title:link {
  color: currentColor; }

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
  cursor: pointer; }

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.5;
  cursor: default; }

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #e24d4d;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: #003a77;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.9em;
}

.cal-month-view .cal-day-cell.cal-drag-over {
  background-color: #516478 !important;
}

.cal-month-view .cal-open-day-events {
  padding: 15px;
  color: white;
	background-color: #516478;
}

.cal-month-view .cal-open-day-events .cal-event {
  position: relative;
  top: 2px; }

.cal-month-view .cal-out-month .cal-day-badge,
.cal-month-view .cal-out-month .cal-event {
  opacity: 0.3; }

/* week view */
.cal-week-view {
  /* stylelint-disable-next-line selector-type-no-unknown */ }
.cal-week-view * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
.cal-week-view .cal-day-headers {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  padding-left: 70px;
  border: 1px solid; }
.cal-week-view .cal-day-headers .cal-header {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  padding: 5px; }
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right: 1px solid; }
.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left: 1px solid; }
.cal-week-view .cal-day-headers span {
  font-weight: 400;
  opacity: 0.5; }
.cal-week-view .cal-day-column {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border-left: solid 1px; }
.cal-week-view .cal-event {
  font-size: 12px;
  border: 1px solid; }
.cal-week-view .cal-time-label-column {
  width: 70px;
  height: 100%; }
.cal-week-view .cal-all-day-events {
  border: solid 1px;
  border-top: 0;
  border-bottom-width: 3px;
  padding-top: 3px;
  position: relative; }
.cal-week-view .cal-all-day-events .cal-day-columns {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  position: absolute;
  top: 0;
  z-index: 0; }
.cal-week-view .cal-all-day-events .cal-events-row {
  position: relative;
  height: 31px;
  margin-left: 70px; }
.cal-week-view .cal-all-day-events .cal-event-container {
  display: inline-block;
  position: absolute; }
.cal-week-view .cal-all-day-events .cal-event-container.resize-active {
  z-index: 1;
  pointer-events: none; }
.cal-week-view .cal-all-day-events .cal-event {
  padding: 0 5px;
  margin-left: 2px;
  margin-right: 2px;
  height: 28px;
  line-height: 28px; }
.cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }
.cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }
.cal-week-view .cal-all-day-events .cal-time-label-column {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px; }
.cal-week-view .cal-all-day-events .cal-resize-handle {
  width: 6px;
  height: 100%;
  cursor: col-resize;
  position: absolute;
  top: 0; }
.cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
  right: 0; }
.cal-week-view .cal-event,
.cal-week-view .cal-header {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }
.cal-week-view .cal-drag-active {
  pointer-events: none;
  z-index: 1; }
.cal-week-view .cal-drag-active * {
  pointer-events: none; }
.cal-week-view .cal-time-events {
  position: relative;
  border: solid 1px;
  border-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex; }
.cal-week-view .cal-time-events .cal-day-columns {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }
.cal-week-view .cal-time-events .cal-day-column {
  position: relative; }
.cal-week-view .cal-time-events .cal-event-container {
  position: absolute;
  z-index: 1; }
.cal-week-view .cal-time-events .cal-event {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px;
  padding: 0 5px;
  line-height: 25px; }
.cal-week-view .cal-time-events .cal-resize-handle {
  width: 100%;
  height: 4px;
  cursor: row-resize;
  position: absolute; }
.cal-week-view .cal-time-events .cal-resize-handle.cal-resize-handle-after-end {
  bottom: 0; }
.cal-week-view .cal-hour-segment {
  position: relative; }
.cal-week-view .cal-hour-segment::after {
  content: '\00a0'; }
.cal-week-view .cal-event-container:not(.cal-draggable) {
  cursor: pointer; }
.cal-week-view .cal-draggable {
  cursor: move; }
.cal-week-view mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour-segment {
  display: block; }
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom: thin dashed; }
.cal-week-view .cal-time {
  font-weight: bold;
  padding-top: 5px;
  width: 70px;
  text-align: center; }
.cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
  display: none; }
.cal-week-view .cal-starts-within-day .cal-event {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }
.cal-week-view .cal-ends-within-day .cal-event {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.cal-week-view .cal-day-headers {
  border-color: #041325; }
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: #041325; }
.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: #041325; }
.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
  background-color: #ededed; }
.cal-week-view .cal-day-column {
  border-left-color: #041325; }
.cal-week-view .cal-event {
  background-color: #d1e8ff;
  border-color: #1e90ff;
  color: #1e90ff; }
.cal-week-view .cal-all-day-events {
  border-color: #e1e1e1; }
.cal-week-view .cal-header.cal-today {
  background-color: #e8fde7; }
.cal-week-view .cal-header.cal-weekend span {
  color: #8b0000; }
.cal-week-view .cal-time-events {
  border-color: #041325; }
.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
  background-color: rgba(0,0,0,.1); }
.cal-week-view .cal-drag-over .cal-hour-segment {
  background-color: #ededed; }
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: #041325; }

.cal-day-view {
  /* stylelint-disable-next-line selector-type-no-unknown */ }
.cal-day-view * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
.cal-day-view .cal-hour-rows {
  width: 100%;
  border: solid 1px;
  overflow-x: auto;
  position: relative; }
.cal-day-view mwl-calendar-day-view-hour-segment,
.cal-day-view .cal-hour-segment {
  display: block; }
.cal-day-view .cal-hour-segment::after {
  content: '\00a0'; }
.cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom: thin dashed; }
.cal-day-view .cal-time {
  font-weight: bold;
  width: 70px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
.cal-day-view .cal-hour-segment.cal-after-hour-start .cal-time {
  display: none; }
.cal-day-view .cal-drag-active .cal-hour-segment {
  pointer-events: none; }
.cal-day-view .cal-event-container {
  position: absolute;
  cursor: pointer; }
.cal-day-view .cal-event-container.resize-active {
  z-index: 1;
  pointer-events: none; }
.cal-day-view .cal-event {
  padding: 5px;
  font-size: 12px;
  border: 1px solid;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%; }
.cal-day-view .cal-all-day-events > * {
  cursor: pointer; }
.cal-day-view .cal-draggable {
  cursor: move; }
.cal-day-view .cal-starts-within-day .cal-event {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }
.cal-day-view .cal-ends-within-day .cal-event {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
.cal-day-view .cal-drag-active {
  z-index: 1; }
.cal-day-view .cal-drag-active * {
  pointer-events: none; }
.cal-day-view .cal-resize-handle {
  width: 100%;
  height: 4px;
  cursor: row-resize;
  position: absolute; }
.cal-day-view .cal-resize-handle.cal-resize-handle-after-end {
  bottom: 0; }

/* end week view */

.cal-week-view .cal-day-headers {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  border: 1px solid #041325;
}

.cal-week-view .cal-day-headers .cal-header {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  padding: 5px; }

.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right: 1px solid #041325; }

.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
  background-color: #0d3e79;
  }

.cal-week-view .cal-day-headers span {
  font-weight: 400;
  opacity: 0.5; }

.cal-week-view .cal-events-row {
  position: relative;
  height: 33px; }

.cal-week-view .cal-event-container {
  display: inline-block;
  position: absolute; }

.cal-week-view .cal-event {
  padding: 0 10px;
  font-size: 12px;
  margin-left: 2px;
  margin-right: 2px;
  height: 30px;
  line-height: 30px;
  background-color: #0a2f5ce1;
  border: 1px solid #041325;
  color: #1e90ff; }

.cal-week-view .cal-event-title:link {
  color: currentColor; }

.cal-week-view .cal-draggable {
  cursor: move; }

.cal-week-view .cal-starts-within-week .cal-event {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.cal-week-view .cal-ends-within-week .cal-event {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.cal-week-view .cal-header.cal-today {
  background-color: #0152b4;
  }

.cal-week-view .cal-header.cal-weekend span {
  color: #e24d4d;
}

.cal-week-view .cal-event,
.cal-week-view .cal-header {
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap; }

.cal-day-view {
  /* stylelint-disable-next-line selector-type-no-unknown */ }
  .cal-day-view .cal-hour-rows {
    width: 100%;
    border: solid 1px #041325;
    overflow-x: scroll;
    position: relative; }
  .cal-day-view .cal-hour:nth-child(odd) {
    background-color: #0a2f5ce1;
    }
  .cal-day-view mwl-calendar-day-view-hour-segment,
  .cal-day-view .cal-hour-segment {
    display: block; }
  .cal-day-view .cal-hour-segment::after {
    content: '\00a0'; }
  .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed #041325; }
  .cal-day-view .cal-time {
    font-weight: bold;
    padding-top: 5px;
    width: 70px;
    text-align: center; }
  .cal-day-view .cal-hour-segment.cal-after-hour-start .cal-time {
    display: none; }
  .cal-day-view .cal-hour-segment:hover,
  .cal-day-view .cal-drag-over .cal-hour-segment {
    background-color: #092649;
    }
  .cal-day-view .cal-event-container {
    position: absolute; }
  .cal-day-view .cal-event {
    padding: 5px;
    font-size: 12px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: #d1e8ff;
    border: 1px solid #041325;
    color: #1e90ff;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .cal-day-view .cal-event-title:link {
    color: currentColor; }
  .cal-day-view .cal-draggable {
    cursor: move; }
  .cal-day-view .cal-starts-within-day .cal-event {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .cal-day-view .cal-ends-within-day .cal-event {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .cal-day-view .cal-all-day-event {
    padding: 8px;
    border: solid 1px; }

.cal-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 11px;
  word-wrap: break-word;
  opacity: 0.9; }

.cal-tooltip.cal-tooltip-top {
  padding: 5px 0;
  margin-top: -3px; }

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.cal-tooltip.cal-tooltip-right {
  padding: 0 5px;
  margin-left: 3px; }

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000; }

.cal-tooltip.cal-tooltip-bottom {
  padding: 5px 0;
  margin-top: 3px; }

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.cal-tooltip.cal-tooltip-left {
  padding: 0 5px;
  margin-left: -3px; }

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.cal-tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.cal-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
