@use "sass:math";
@import './mixins';

$background: #516478;
$default-color: #fff;
$main-ff: 'Open Sans', sans-serif;
$second-ff: 'Hind', sans-serif;
$main-fs: 16px;
$main-fw: 400;
$module: 10px;
$main-lh: 1.5;
$module-rem: math.div($module, $main-fs) * 1rem;
$shape: 0;

$income-color: #64b5f6;
$expense-color: #fff;

//Media
$max543: "only screen and ( max-width: 543px )";
$min544: "only screen and ( min-width: 544px )";
$max767: "only screen and ( max-width: 767px )";
$min768: "only screen and ( min-width: 768px )";
$max991: "only screen and ( max-width: 991px )";
$min992: "only screen and ( min-width: 992px )";
$max1199: "only screen and ( max-width: 1199px )";
$min1200: "only screen and ( min-width: 1200px )";

$boxed-width: 1200px;
$content-h-indents: $module-rem * 3;
$content-v-indents: $module-rem * 3;

$nav-bar-height: $module-rem * 6;
$horizontal-menu-height: $module-rem * 5;
$nav-bar-bg: #fff;

$primary-bg: #516478;
$primary-light: #fff;

$side-bar-width: 28 * $module-rem;
$side-bar-bg: #fff;
$side-bar-bg-dark: #302d2d;

$footer-height: 4 * $module-rem;

$animation: ease-in-out;

$success-color: #81c784;
$success-contrast: #fff;
$info-color: #64b5f6;
$info-contrast: #fff;
$warning-color: #ffb74d;
$warning-contrast: #fff;
$error-color: #e24d4d;
$error-contrast: #fff;

$main-palette: (
  50: #e1e4e8,
  100: #b5bdc5,
  200: #83919f,
  300: #516478,
  400: #2c435b,
  500: #07223e,
  600: #061e38,
  700: #051930,
  800: #041428,
  900: #020c1b,
  A100: #5985ff,
  A200: #2660ff,
  A400: #0041f2,
  A700: #003ad9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
@function main-palette($key: $main-palette) {
  @return map-get($main-palette, $key);
}
@function main-contrast($key: $main-palette) {
  @return map-get(map-get($main-palette, contrast), $key);
}

$accent-palette: (
  50: #ffb889,
  100: #ffae79,
  200: #ffa76d,
  300: #ff9751,
  400: #ff924a,
  500: #ff8534,
  600: #ff771d,
  700: #ff6a07,
  800: #e86107,
  900: #d15706,
  A100: #fff,
  A200: #eff0ff,
  A400: #bcc1ff,
  A700: #a2aaff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
@function accent-palette($key: $accent-palette) {
  @return map-get($accent-palette, $key);
}
@function accent-contrast($key: $accent-palette) {
  @return map-get(map-get($accent-palette, contrast), $key);
}
